import React from "react";
import "./style.css";

export default function App() {
  return (
    <div>
      <h1>Hello Cloudflare!</h1>
      <p>Deploy React App to Cloudflare</p>
    </div>
  );
}
